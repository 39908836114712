/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    a: "a",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 665px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 100%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBQEE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHcr8tE3sIriQOg/8QAGRAAAwADAAAAAAAAAAAAAAAAAQIDABAS/9oACAEBAAEFAmdUwWQ6vJzTij4o5Xf/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAwEBPwECP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EAB8QAAECBgMAAAAAAAAAAAAAAAEAIgIQERIgITJRkf/aAAgBAQAGPwJxouUr4NoN9QHWH//EABsQAAIDAAMAAAAAAAAAAAAAAAERACExECBR/9oACAEBAAE/IRDA0JKj9C4FMqp5CeD5ZrHuWifT/9oADAMBAAIAAwAAABDDDwD/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAwEBPxBQKx//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPxBWP//EAB8QAQACAQMFAAAAAAAAAAAAAAERQQAhMWEQIHGRof/aAAgBAQABPxABRA7vgvC5C0E0vZ0E7QpMJ24xwkkBAG+WvuIgsUrg37P/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"49ers\"\n        title=\"49ers\"\n        src=\"/static/e70f9065df26f9bfa981b33ab6d71966/0d8e0/49ers.jpg\"\n        srcset=\"/static/e70f9065df26f9bfa981b33ab6d71966/4d5fb/49ers.jpg 166w,\n/static/e70f9065df26f9bfa981b33ab6d71966/558f0/49ers.jpg 333w,\n/static/e70f9065df26f9bfa981b33ab6d71966/0d8e0/49ers.jpg 665w,\n/static/e70f9065df26f9bfa981b33ab6d71966/2ee6c/49ers.jpg 900w\"\n        sizes=\"(max-width: 665px) 100vw, 665px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/california-land-mythical-beasts-amazon-women/"
  }, "California"), " is known for being one of the main destinations for ", React.createElement("u", null, "gold prospectors"), " in ", React.createElement(_components.strong, null, "1849"), ". "), "\n", React.createElement(_components.p, null, "That year saw many a bearded man rush to the hills and streams of California in search of gold-colored riches. "), "\n", React.createElement(_components.p, null, "It was on this legacy and the year that the ", React.createElement(_components.strong, null, "San Francisco 49ers"), " decided to name their team –after what prospectors were called back then: 49ers. "), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "The football franchise was born in 1946"), " (almost 100 years after the height of the gold rush) and, at that time, was part of the AAFC. "), "\n", React.createElement(_components.p, null, "The 49ers began in San Francisco which has been their home since their inception –one of the few NFL teams that are able to say they never left.  Additionally, the 49ers have had a very successful record having won 5 super bowl titles – behind only the ", React.createElement(_components.a, {
    href: "/pittsburgh-steelers-storied-history/"
  }, "Pittsburgh Steelers"), " in super bowl rings. "), "\n", React.createElement(_components.p, null, "Despite California being a sports-centered state, professional baseball didn't come until after a professional NFL franchise was established making the 49ers the oldest professional sports team in California (just before the Rams came to LA in 1946). "), "\n", React.createElement(_components.p, null, "Throughout their history they have had several iterations of uniform color combinations and design but gold has almost always been a part of the design to signify the gold of the gold rush. "), "\n", React.createElement(_components.p, null, "Historically, the three biggest rivals of the 49ers are the ", React.createElement(_components.strong, null, "Rams"), ", ", React.createElement(_components.strong, null, "Cardinals"), " and ", React.createElement(_components.strong, null, "Seahawks"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
